import React from 'react';
import { graphql, Link } from 'gatsby'
import Layout from '../components/layout'
import styles from './contact.module.css'
import Hero from '../components/hero'

const success = ({data}) => {
    const [author] = data.allContentfulPerson.edges
    const image = data.allImageSharp.edges[0].node.fluid
    return(
    <Layout>
        {console.log("PEO", data)}
        <div style={{ background: '#fff' }} className={styles.container}>
        <Hero data={author.node}  image={image}/>
         <div className="wrapper">
         <p className={styles.title}>Thank you for contacing Us!</p>
         <p className={styles.title}>Ww will get back to you as sonn as possible!</p>
         <p className={styles.backtotop}><Link to="/">Back To Top</Link></p>
        </div>
        </div>
    </Layout>
    );
};

export default success;


export const pageQuery = graphql`
query SusccesssIndex {
    allContentfulPerson {
      edges {
        node {
          title
          shortBio {
            shortBio
          }
          name
        }
      }
    }
    allImageSharp {
      edges {
        node {
            fluid(maxWidth: 2000){
                ...GatsbyImageSharpFluid
                originalName
          }
        }
      }
    }
  }
`